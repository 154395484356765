#gradient-canvas {
  width: 100%;
  height: 100%;
  --gradient-color-1: #007aa6;
  --gradient-color-2: #b40000;
  --gradient-color-3: #b12d2d;
  --gradient-color-4: #d5806b;
}

*[data-redaction="[Redacted Content]"] {
  background-color: #f4ebe1;
}
*[data-redaction="[Name Redacted]"] {
  background-color: #e1f1f4;
}
*[data-redaction="[Place Redacted]"] {
  background-color: #e1f4e3;
}
*[data-redaction="[URL Redacted]"] {
  background-color: #e6e1f4;
}
*[data-redaction="[School Redacted]"] {
  background-color: #f4f2e1;
}
*[data-redaction="[Organization Redacted]"] {
  background-color: #f4e1e1;
}

.Tab__selected-sample::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(to right, #cdffc8, #94b9ff);
  z-index: -1;
}
.Tab__selected-go::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(to right, #0cc0df, #ffde59);
  z-index: -1;
}
.Tab__selected-pro::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(to right, #ffde59, #ff914d);
  z-index: -1;
}
.Tab__selected-ultra::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(to right, #ff914e, #ff37b2);
  z-index: -1;
}
.Tab__selected-max::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(to right, #ef38aa, #9659de);
  z-index: -1;
}
